// countryCodes.js
export const countryCodes = [
    { code: '+61', country: 'Australia' },
    { code: '+880', country: 'Bangladesh' },
    { code: '+32', country: 'Belgium' },
    { code: '+55', country: 'Brazil' },
    { code: '+86', country: 'China' },
    { code: '+506', country: 'Costa Rica' },
    { code: '+385', country: 'Croatia' },
    { code: '+357', country: 'Cyprus' },
    { code: '+420', country: 'Czech Republic' },
    { code: '+45', country: 'Denmark' },
    { code: '+20', country: 'Egypt' },
    { code: '+372', country: 'Estonia' },
    { code: '+679', country: 'Fiji' },
    { code: '+33', country: 'France' },
    { code: '+49', country: 'Germany' },
    { code: '+30', country: 'Greece' },
    { code: '+91', country: 'India' },
    { code: '+353', country: 'Ireland' },
    { code: '+972', country: 'Israel' },
    { code: '+39', country: 'Italy' },
    { code: '+81', country: 'Japan' },
    { code: '+254', country: 'Kenya' },
    { code: '+371', country: 'Latvia' },
    { code: '+370', country: 'Lithuania' },
    { code: '+60', country: 'Malaysia' },
    { code: '+960', country: 'Maldives' },
    { code: '+52', country: 'Mexico' },
    { code: '+373', country: 'Moldova' },
    { code: '+212', country: 'Morocco' },
    { code: '+31', country: 'Netherlands' },
    { code: '+64', country: 'New Zealand' },
    { code: '+234', country: 'Nigeria' },
    { code: '+47', country: 'Norway' },
    { code: '+63', country: 'Philippines' },
    { code: '+48', country: 'Poland' },
    { code: '+351', country: 'Portugal' },
    { code: '+7', country: 'Russia' },
    { code: '+65', country: 'Singapore' },
    { code: '+27', country: 'South Africa' },
    { code: '+82', country: 'South Korea' },
    { code: '+34', country: 'Spain' },
    { code: '+46', country: 'Sweden' },
    { code: '+41', country: 'Switzerland' },
    { code: '+66', country: 'Thailand' },
    { code: '+90', country: 'Turkey' },
    { code: '+44', country: 'United Kingdom' },
    { code: '+1', country: 'USA/Canada' },
    { code: '+598', country: 'Uruguay' },
    { code: '+84', country: 'Vietnam' },
  ];
  