import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import has from 'lodash.has';
import Grid from '@mui/material/Grid';
import { TextField, Button, MenuItem, Select, FormControl, Alert,InputLabel } from '@mui/material';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import routes from 'routes';
import bgImage from 'assets/images/linis/IMG_0340.jpeg';
import { countryCodes } from '../../../assets/countryCodes';
import emailjs from 'emailjs-com';

// Validation Schema using Yup and lodash.has
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email address'),
  countryCode: Yup.string(),
  phone: Yup.string(),
  age: Yup.string().required('Please enter your age'),
  location: Yup.string().required('Please enter your location'),
  occupation: Yup.string().required('Please enter your occupation'),
  yogaExp: Yup.string().required('Please select if you have experience in Yoga'),
  healthIssues: Yup.string().required('Please select if you have health issues'),
  mentionHealthIssues: Yup.string(),
}).test(
  'email-or-phone',
  'Either email or phone is required',
  (value) => has(value, 'email') || has(value, 'phone') // Ensure either email or phone is provided
);

const sendEmail = (values) => {
  const serviceID = 'service_gsy9xx1';  // Replace with your EmailJS service ID
  const templateID = 'template_6j0p0mo'; // Replace with your EmailJS template ID
  const userID = 'imRcax62smnR2KA8j';  // Replace with your EmailJS user ID

  return emailjs.send(serviceID, templateID, values, userID);
};

function Enroll() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    console.log('values',values)
    sendEmail(values)
      .then(() => {
        setAlertMessage('Message sent successfully!');
        setAlertSeverity('success');
        setShowAlert(true);
        resetForm();
      })
      .catch(() => {
        setAlertMessage('Failed to send message.');
        setAlertSeverity('error');
        setShowAlert(true);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} sticky transparent light />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: 'none', lg: 'flex' }}
            width="calc(100% - 2rem)"
            height="100vh"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: 'auto', lg: 6 }}
          mr={{ xs: 'auto', lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Enroll
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Enter all mandatory information and hit &quot;Enroll&quot;
              </MKTypography>

              {showAlert && (
                <Alert severity={alertSeverity} onClose={() => setShowAlert(false)}>
                  {alertMessage}
                </Alert>
              )}

              <Formik
                initialValues={{ fullName: '',
                   email: '',
                   countryCode: '',
                   phone: '',
                   age: '',
                   location:'',
                   occupation:'',
                   yogaExp:'',
                   healthIssues:'',
                   mentionHealthIssues:'' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting, handleChange, values, isValid }) => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Field
                          name="fullName"
                          as={TextField}
                          label="Full Name"
                          fullWidth
                          variant="standard"
                          error={touched.fullName && Boolean(errors.fullName)}
                          helperText={touched.fullName && errors.fullName}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Field
                          name="age"
                          as={TextField} 
                          type="age"
                          label="Age"
                          fullWidth
                          variant="standard"
                          error={touched.age && Boolean(errors.age)}
                          helperText={touched.age && errors.age}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} >
                        <FormControl fullWidth variant="standard">
                        <InputLabel >Country Code</InputLabel>                          
                          <Select
                            name="countryCode"
                            value={values.countryCode}
                            onChange={handleChange}
                            error={touched.countryCode && Boolean(errors.countryCode)}
                            label="Country Code"
                            sx={{
                              paddingBottom: '9px', // Increase padding between text and underline
                            }}
                          >
                            {countryCodes.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {`${option.code} (${option.country})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Field
                          name="phone"
                          as={TextField}
                          type="text"
                          label="Phone"
                          fullWidth
                          variant="standard"
                          error={touched.phone && Boolean(errors.phone)}
                          helperText={touched.phone && errors.phone}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name="email"
                          as={TextField} 
                          type="email"
                          label="Email"
                          fullWidth
                          variant="standard"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name="occupation"
                          as={TextField} 
                          type="occupation"
                          label="Occupation"
                          fullWidth
                          variant="standard"
                          error={touched.occupation && Boolean(errors.occupation)}
                          helperText={touched.occupation && errors.occupation}
                        />
                      </Grid>                      
                      <Grid item xs={12}>
                        <Field
                          name="location"
                          as={TextField} 
                          type="location"
                          label="Location"
                          fullWidth
                          variant="standard"
                          error={touched.location && Boolean(errors.location)}
                          helperText={touched.location && errors.location}
                        />
                      </Grid>  

                      <Grid item xs={12}>
                      <InputLabel >Any prior yoga experience?</InputLabel>  
                      <Field name="yogaExp">
                      {({ field }) => (
                        <RadioGroup
                          {...field}
                          value={values.yogaExp}
                          onChange={handleChange}                          
                        >
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                      )}
                      </Field>
                      </Grid>  
                      <Grid item xs={12} md={6}>
                      <InputLabel >Do you have any health issues?</InputLabel>  
                      <Field name="healthIssues">
                      {({ field }) => (
                        <RadioGroup
                          {...field}
                          value={values.healthIssues}
                          onChange={handleChange}                          
                        >
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                      )}
                      </Field>
                      </Grid>    
                      <Grid item xs={12}>
                        <Field
                          name="mentionHealthIssues"
                          as={TextField}
                          label="If yes, please mention"
                          placeholder="If yes, please mention"
                          fullWidth
                          variant="standard"
                          multiline
                          rows={6}
                          error={touched.mentionHealthIssues && Boolean(errors.mentionHealthIssues)}
                          helperText={touched.mentionHealthIssues && errors.mentionHealthIssues}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !isValid}
                        style={{ backgroundColor: '#1A73E8',color:'white' }}                        
                      >
                        {isSubmitting ? 'Sending...' : 'Enroll'}
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
    </>
  );
}

export default Enroll;
