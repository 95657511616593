/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Button from '@mui/material/Button';


// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/linis/home.jpeg";
import GoogleReviews from "./sections/GoogleReviews";


function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              backgroundColor="#1A73E8"
            >
                Lini&apos;s Yoga
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3} backgroundColor="#1A73E8">
              Learn yoga in studio, corporate or online via Google Meet
            </MKTypography>
            <Button target={"_blank"} variant="contained" href="/pages/landing-pages/enroll" sx={{ color: ({ palette: { white } }) => white.main }}>Enroll</Button>            
            <MKTypography variant="h6" color="white" mt={8} mb={1} backgroundColor="#1A73E8">
              Find me on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
            <MKTypography component="a" variant="body1" color="white" target={"_blank"} href="https://www.instagram.com/linis_2711" mr={1}>
                <i className="fab fa-instagram" />
              </MKTypography>
              {/*<MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#">
                <i className="fab fa-google-plus" />
              </MKTypography>*/}
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <GoogleReviews />
      </Card>
    </>
  );
}

export default Presentation;
