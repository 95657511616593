import { useEffect } from 'react';

const GoogleReviews = () => {
  useEffect(() => {
    // Check if the script is already loaded to avoid duplicate scripts
    const scriptId = 'EmbedSocialHashtagScript';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://embedsocial.com/cdn/ht.js';
      document.head.appendChild(script);
    }
  }, []);

  return (
    <div>
      <div
        className="embedsocial-hashtag"
        data-ref="b5027ecd29d0e433c12ee31b60b624ed3d6e1fca"
      >
        <a
          className="feed-powered-by-es feed-powered-by-es-slider-img"
          href="https://embedsocial.com/blog/embed-google-reviews/"
          target="_blank"
          rel="noopener noreferrer"
          title="Embed Google reviews"
        >
          <img
            src="https://embedsocial.com/cdn/images/embedsocial-icon.png"
            alt="EmbedSocial"
          />
          Embed Google reviews
        </a>
      </div>
    </div>
  );
};

export default GoogleReviews;
